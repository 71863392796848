import http from "./HttpService";
//import _ from "lodash";

class ChartDataService {
  constructor() {
    this.slug = 'charts';
    this.http = http;
  }

  loadOpenBillables() {
    return this.http.get("/" + this.slug + "/openbillables")
  }

  loadPerformance() {
    return this.http.get("/" + this.slug + "/performance")
  }
}

export default new ChartDataService();
