<script>

import { Line } from 'vue3-chart-v2'
import ChartDataService from "@/services/ChartDataService";

export default {
  name: "MonthPerformance",
  extends: Line,
  mounted () {
    ChartDataService.loadPerformance().then(response => {
      const customers = response.data.data.customers;
      const matrix = response.data.data.matrix;
      const labels = response.data.data.labels;

      let datasets = [];

      for (const [customerId, dataArray] of Object.entries(matrix)) {
        let dataset = {
          label: customers[customerId].company_name,
          fill: true,
          backgroundColor: customers[customerId].color2,
          pointBackgroundColor: customers[customerId].color1,
          borderColor: customers[customerId].color1,
          pointHighlightStroke: customers[customerId].color1,
          borderCapStyle: 'butt',
          data: dataArray,
        };

        datasets.push(dataset);
      }

      this.renderChart({
        labels: labels,
        datasets: datasets,
      }, {
        responsive: true,
        maintainAspectRatio: false,
        // Can't just just `stacked: true` like the docs say
        scales: {
          yAxes: [{
            stacked: true,
          }]
        },
        animation: {
          duration: 750,
        },
      })
    });
  }
}
</script>

<style scoped>

</style>
