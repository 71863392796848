<template>
  <div class="row gx-5 gy-5 mt-2">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Billables to Invoice per Customer</h5>
          <div class="chart">
            <OpenBillables />
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Performance the last 30 days</h5>
          <div class="chart">
            <MonthPerformance />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MonthPerformance from "@/components/Chart/MonthPerformance";
import OpenBillables from  "@/components/Chart/OpenBillables";

export default {
  name: "UserCharts",
  components: { MonthPerformance, OpenBillables }
}
</script>

<style lang="scss">

.chart {
  height: 400px;
  width: 100%;
  position: relative;
  overflow: hidden;
}
</style>
