<script>

import { Bar } from 'vue3-chart-v2'
import ChartDataService from "@/services/ChartDataService";

export default {
  name: "OpenBillables",
  extends: Bar,
  mounted () {
    ChartDataService.loadOpenBillables().then(response => {
      let labels = [];
      let data = [];
      let lineColors = [];
      let fillColors = [];
      const customers = response.data.data.customers;
      const matrix = response.data.data.matrix;

      Object.values(matrix).forEach(v => {
        data.push(v);
      });

      Object.values(customers).forEach(c => {
        labels.push(c.company_name);
        lineColors.push(c.color1);
        fillColors.push(c.color2);
      });

      this.renderChart({
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: fillColors,
            borderColor: lineColors
          }
        ]
      }, {
        responsive: true,
        maintainAspectRatio: false,

        legend: {
          display: false,
        },

        // Can't just just `stacked: true` like the docs say
        scales: {
          yAxes: [{
            stacked: true,
          }]
        },
        animation: {
          duration: 750,
        },
      })
    });
  }
}
</script>

<style scoped>

</style>
